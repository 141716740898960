import React, { useState, useRef, useEffect } from "react"
// import { graphql } from "gatsby"
import Swiper from "react-id-swiper"

import Heading from "../../components/Heading"
// import ProductTile from "../../components/ProductTile"
import Image from "../../components/Image"
import EmailCapture from "../../components/EmailCapture"

import styles from "./irinashayk-ss21.module.scss"
import SEO from "../../components/SEO"

const IrinashaykSS21 = ({ data, location }) => {
  // const {
  //   nodes
  // } = data.allProductStyle

  const videoEl1 = useRef(null)
  const videoEl2 = useRef(null)
  const videoEl3 = useRef(null)
  const videoEl4 = useRef(null)
  const videoEl5 = useRef(null)
  const videoEl6 = useRef(null)
  const videoEl7 = useRef(null)

  const [swiper1, getSwiper1] = useState(null)
  const [swiper2, getSwiper2] = useState(null)

  const swiperParams = {
    containerClass: `${styles.swiperContainer} swiper-container`,
    WrapperEl: "ol",
    threshold: 10,
    spaceBetween: 0,
    slidesPerView: 1,
    loop: true,
    pagination: {
      el: `.swiper-pagination`,
      clickable: true,
    },
  }

  const swiperParams1 = {
    getSwiper: getSwiper1,
    ...swiperParams
  }

  const swiperParams2 = {
    getSwiper: getSwiper2,
    ...swiperParams
  }

  const handleVideoClick = (videoEl) => {
    if (videoEl.current.paused) {
      videoEl.current.play()
      videoEl.current.setAttribute("data-playing", "true")
    } else {
      videoEl.current.pause()
      videoEl.current.setAttribute("data-playing", "false")
    }
  }

  useEffect(() => {
    videoEl1.current.addEventListener("click", () => handleVideoClick(videoEl1), true)
    return (
      videoEl1.current.removeEventListener("click", handleVideoClick)
    )
  }, [videoEl1])

  useEffect(() => {
    videoEl2.current.addEventListener("click", () => handleVideoClick(videoEl2), true)
    return (
      videoEl2.current.removeEventListener("click", handleVideoClick)
    )
  }, [videoEl2])

  useEffect(() => {
    videoEl3.current.addEventListener("click", () => handleVideoClick(videoEl3), true)
    return (
      videoEl3.current.removeEventListener("click", handleVideoClick)
    )
  }, [videoEl3])

  useEffect(() => {
    videoEl4.current.addEventListener("click", () => handleVideoClick(videoEl4), true)
    return (
      videoEl4.current.removeEventListener("click", handleVideoClick)
    )
  }, [videoEl4])

  useEffect(() => {
    videoEl5.current.addEventListener("click", () => handleVideoClick(videoEl5), true)
    return (
      videoEl5.current.removeEventListener("click", handleVideoClick)
    )
  }, [videoEl5])

  useEffect(() => {
    videoEl6.current.addEventListener("click", () => handleVideoClick(videoEl6), true)
    return (
      videoEl6.current.removeEventListener("click", handleVideoClick)
    )
  }, [videoEl6])

  useEffect(() => {
    videoEl7.current.addEventListener("click", () => handleVideoClick(videoEl7), true)
    return (
      videoEl7.current.removeEventListener("click", handleVideoClick)
    )
  }, [videoEl6])

  return (
    <>
      <SEO
        title="Irina Shayk x Tamara Mellon"
        description="The woman who designs the world’s sexiest shoes and the world’s sexiest supermodel have come together to design a capsule collection."
        url={location.pathname}
      />

      {/* Hero Video */}
      <section className={styles.moduleVideo1}>
        {typeof window !== "undefined" && window.outerWidth < 768 &&
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <video autoPlay loop muted playsInline className={styles.video}>
          <source
            src="https://cdn.shopify.com/s/files/1/1103/4464/files/SWEET_REVENGE_MOBILE_1.mp4?v=1634184899"
            type="video/mp4" />
        </video>
        }
        {typeof window !== "undefined" && window.outerWidth >= 768 &&
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <video autoPlay loop muted playsInline className={styles.video}>
          <source
            src="https://cdn.shopify.com/s/files/1/1103/4464/files/SWEET_REVENGE_DESKTOP.mp4?v=1634144165"
            type="video/mp4" />
        </video>
        }
      </section>

      {/* FW Heading and Text */}
      <section className={styles.module1}>
        <Heading
          tag="h1"
          level="1"
          className={styles.heading}
        >
          IRINA SHAYK x TAMARA MELLON: Capsule II
        </Heading>
        <p className={styles.text}>
          Like attracts like. So when Tamara, the designer of the world’s sexiest shoes, and Irina Shayk, the world’s sexiest supermodel, joined forces earlier this year on a capsule collection, it’s no surprise that the pair couldn’t stay away from each other for long.
        </p>
      </section>

      {/* FW Gallery 1x1 */}
      <section className={styles.module2}>
        <div className={styles.swiper}>
          <Swiper {...swiperParams1}>
            <li
              key={1}
              className={styles.swiperSlide}
            >
              <Image
                image={{
                  desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/IRINA_LP_GALLERY_1_850x.jpg?v=1633464022",
                  mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/IRINA_LP_GALLERY_1_850x.jpg?v=1633464022",
                }}
                altText="Irina Shayk Carousel Image"
              />
            </li>
            <li
              key={2}
              className={styles.swiperSlide}
            >
              <Image
                image={{
                  desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/IRINA_LP_GALLERY_2_850x.jpg?v=1633464023",
                  mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/IRINA_LP_GALLERY_2_850x.jpg?v=1633464023",
                }}
                altText="Irina Shayk Carousel Image"
              />
            </li>
            <li
              key={3}
              className={styles.swiperSlide}
            >
              <Image
                image={{
                  desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/IRINA_LP_GALLERY_3_850x.jpg?v=1633464023",
                  mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/IRINA_LP_GALLERY_3_850x.jpg?v=1633464023",
                }}
                altText="Irina Shayk Carousel Image"
              />
            </li>
            <li
              key={4}
              className={styles.swiperSlide}
            >
              <Image
                image={{
                  desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/IRINA_LP_GALLERY_4_850x.jpg?v=1633464022",
                  mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/IRINA_LP_GALLERY_4_850x.jpg?v=1633464022",
                }}
                altText="Irina Shayk Carousel Image"
              />
            </li>
            <li
              key={5}
              className={styles.swiperSlide}
            >
              <Image
                image={{
                  desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/IRINA_LP_GALLERY_5_850x.jpg?v=1633464023",
                  mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/IRINA_LP_GALLERY_5_850x.jpg?v=1633464023",
                }}
                altText="Irina Shayk Carousel Image"
              />
            </li>
            <li
              key={6}
              className={styles.swiperSlide}
            >
              <Image
                image={{
                  desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/IRINA_LP_GALLERY_6_850x.jpg?v=1633464022",
                  mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/IRINA_LP_GALLERY_6_850x.jpg?v=1633464022",
                }}
                altText="Irina Shayk Carousel Image"
              />
            </li>
            <li
              key={7}
              className={styles.swiperSlide}
            >
              <Image
                image={{
                  desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/IRINA_LP_GALLERY_7_850x.jpg?v=1633464022",
                  mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/IRINA_LP_GALLERY_7_850x.jpg?v=1633464022",
                }}
                altText="Irina Shayk Carousel Image"
              />
            </li>
          </Swiper>
          <button
            className={`${styles.swiperButtonPrev} swiper-navigation-prev`}
            onClick={() => swiper1.slidePrev()}
            aria-label="Previous"
          />
          <button
            className={`${styles.swiperButtonNext} swiper-navigation-next`}
            onClick={() => swiper1.slideNext()}
            aria-label="Next"
          />
        </div>
        <p className={styles.text}>
          For the second installment of their collaboration, Tamara and Irina leaned even further into the interplay of form and function, resulting in three brand new silhouettes that refuse to compromise on either.<br /><br />
          They believe that at the intersection of these two ideals, a woman feels most powerful.
        </p>
      </section>

      {/* FW Products 1x1x1 */}
      {/*<ul className={styles.module4}>*/}
        {/*<ProductTile*/}
        {/*  index="0"*/}
        {/*  style={nodes[2]}*/}
        {/*  customStyles={styles}*/}
        {/*  origin={`irinashayk-ss21`}*/}
        {/*  collection="Irina Shayk FW21"*/}
        {/*/>*/}
        {/*<li>*/}
        {/*  <p className={styles.text}>*/}
        {/*    Years ago, Tamara defined an entire category with the pant-boot (AKA bants). With a chunky lug sole and side zip, this is her sleekest and most wearable version yet.*/}
        {/*  </p>*/}
        {/*</li>*/}
        {/*<ProductTile*/}
        {/*  index="1"*/}
        {/*  style={nodes[0]}*/}
        {/*  customStyles={styles}*/}
        {/*  origin={`irinashayk-ss21`}*/}
        {/*  collection="Irina Shayk FW21"*/}
        {/*/>*/}
        {/*<li>*/}
        {/*  <p className={styles.text}>*/}
        {/*    Good genes run in the family. A cousin to Sweet Revenge, Iri goes high or low (it’s all in the detachable zip), depending on what life throws at you.*/}
        {/*  </p>*/}
        {/*</li>*/}
        {/*<ProductTile*/}
        {/*  index="2"*/}
        {/*  style={nodes[1]}*/}
        {/*  customStyles={styles}*/}
        {/*  origin={`irinashayk-ss21`}*/}
        {/*  collection="Irina Shayk FW21"*/}
        {/*/>*/}
        {/*<li>*/}
        {/*  <p className={styles.text}>*/}
        {/*    You know Tamara and Irina had to have at least one high heel. Like them, Spike doesn’t take no for an answer.*/}
        {/*  </p>*/}
        {/*</li>*/}
      {/*</ul>*/}

      {/* In Bed Videos */}
      <section className={styles.moduleVideo3} id="in-bed-video">
        <Heading
          tag="h1"
          level="1"
          className={styles.heading}
        >
          In Bed With Tamara and Irina
        </Heading>
        <div className={styles.video1x1x1}>
          <div className={styles.videoContainer} id="in-bed-video-1">
            {// eslint-disable-next-line jsx-a11y/media-has-caption
              <video playsInline poster="https://cdn.shopify.com/s/files/1/1103/4464/files/BED1_COVER_850x.jpg?v=1625003146" className={styles.video} ref={videoEl4} data-playing="false">
                <source
                  src="https://cdn.shopify.com/s/files/1/1103/4464/files/BED1.mp4?v=1625002958"
                  type="video/mp4" />
              </video>
            }
            <button className={styles.playBtn} aria-label="Play" />
            <p className={styles.text}>The two talk numbers (shoe counts, of course).</p>
          </div>
          <div className={styles.videoContainer} id="in-bed-video-2">
            {// eslint-disable-next-line jsx-a11y/media-has-caption
              <video playsInline poster="https://cdn.shopify.com/s/files/1/1103/4464/files/BED2_COVER_850x.jpg?v=1625003146" className={styles.video} ref={videoEl6} data-playing="false">
                <source
                  src="https://cdn.shopify.com/s/files/1/1103/4464/files/BED2.mp4?v=1625002958"
                  type="video/mp4" />
              </video>
            }
            <button className={styles.playBtn} aria-label="Play" />
            <p className={styles.text}>A tête-à-tête about style, men, seduction moves, and shoes.</p>
          </div>
          <div className={styles.videoContainer} id="in-bed-video-3">
            {// eslint-disable-next-line jsx-a11y/media-has-caption
              <video playsInline poster="https://cdn.shopify.com/s/files/1/1103/4464/files/BED3_COVER_850x.jpg?v=1625762936" className={styles.video} ref={videoEl7} data-playing="false">
                <source
                  src="https://cdn.shopify.com/s/files/1/1103/4464/files/1-1_IN_BED_WITH_Chapter_III_FINAL.mp4?v=1625762932"
                  type="video/mp4" />
              </video>
            }
            <button className={styles.playBtn} aria-label="Play" />
            <p className={styles.text}>Fashion grievances, life advice—Irina and Tamara get real.</p>
          </div>
        </div>
      </section>

      {/* SS Heading and Text */}
      <section className={styles.module1}>
        <Heading
          tag="h1"
          level="1"
          className={styles.heading}
        >
          IRINA SHAYK x TAMARA MELLON: Capsule I
        </Heading>
        <p className={styles.text}>
          Tamara’s life work is encouraging women to stand in their power—and few do it quite like Irina. Born from months of sketching, material selection, fittings, and mom-to-mom bonding (all over Zoom), Capsule I is a collection of shoes to wear while just being... you. (So always.)
        </p>
      </section>

      {/* SS Gallery 1x1 */}
      <section className={styles.module2}>
        <div className={styles.swiper}>
          <Swiper {...swiperParams2}>
            {/*<li*/}
            {/*  key={12}*/}
            {/*  className={styles.swiperSlide}*/}
            {/*>*/}
            {/*  <Image*/}
            {/*    image={{*/}
            {/*      desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/escape_gallery_1_850x.jpg?v=1624470408",*/}
            {/*      mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/escape_gallery_1_850x.jpg?v=1624470408",*/}
            {/*    }}*/}
            {/*    altText="Irina Shayk Carousel Image"*/}
            {/*  />*/}
            {/*</li>*/}
            <li
              key={13}
              className={styles.swiperSlide}
            >
              <Image
                image={{
                  desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/escape_gallery_2_850x.jpg?v=1624470409",
                  mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/escape_gallery_2_850x.jpg?v=1624470409",
                }}
                altText="Irina Shayk Carousel Image"
              />
            </li>
            <li
              key={14}
              className={styles.swiperSlide}
            >
              <Image
                image={{
                  desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/escape_gallery_3_850x.jpg?v=1624470408",
                  mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/escape_gallery_3_850x.jpg?v=1624470408",
                }}
                altText="Irina Shayk Carousel Image"
              />
            </li>
            <li
              key={15}
              className={styles.swiperSlide}
            >
              <Image
                image={{
                  desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/escape_gallery_4_850x.jpg?v=1624470408",
                  mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/escape_gallery_4_850x.jpg?v=1624470408",
                }}
                altText="Irina Shayk Carousel Image"
              />
            </li>
            <li
              key={16}
              className={styles.swiperSlide}
            >
              <Image
                image={{
                  desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/escape_gallery_5_850x.jpg?v=1624470409",
                  mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/escape_gallery_5_850x.jpg?v=1624470409",
                }}
                altText="Irina Shayk Carousel Image"
              />
            </li>
            <li
              key={1}
              className={styles.swiperSlide}
            >
              <Image
                image={{
                  desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/ISxTM_SunValley_gallery_1_850x.jpg?v=1623346669",
                  mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/ISxTM_SunValley_gallery_1_850x.jpg?v=1623346669",
                }}
                altText="Irina Shayk Carousel Image"
              />
            </li>
            <li
              key={2}
              className={styles.swiperSlide}
            >
              <Image
                image={{
                  desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/ISxTM_SunValley_gallery_2_850x.jpg?v=1623346669",
                  mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/ISxTM_SunValley_gallery_2_850x.jpg?v=1623346669",
                }}
                altText="Irina Shayk Carousel Image"
              />
            </li>
            <li
              key={3}
              className={styles.swiperSlide}
            >
              <Image
                image={{
                  desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/ISxTM_SunValley_gallery_3_850x.jpg?v=1623346669",
                  mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/ISxTM_SunValley_gallery_3_850x.jpg?v=1623346669",
                }}
                altText="Irina Shayk Carousel Image"
              />
            </li>
            <li
              key={4}
              className={styles.swiperSlide}
            >
              <Image
                image={{
                  desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/ISxTM_SunValley_gallery_4_850x.jpg?v=1623346669",
                  mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/ISxTM_SunValley_gallery_4_850x.jpg?v=1623346669",
                }}
                altText="Irina Shayk Carousel Image"
              />
            </li>
            <li
              key={5}
              className={styles.swiperSlide}
            >
              <Image
                image={{
                  desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/ISxTM_SunValley_gallery_5_850x.jpg?v=1623346669",
                  mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/ISxTM_SunValley_gallery_5_850x.jpg?v=1623346669",
                }}
                altText="Irina Shayk Carousel Image"
              />
            </li>
            <li
              key={6}
              className={styles.swiperSlide}
            >
              <Image
                image={{
                  desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/ISxTM_SunValley_gallery_6_850x.jpg?v=1623346669",
                  mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/ISxTM_SunValley_gallery_6_850x.jpg?v=1623346669",
                }}
                altText="Irina Shayk Carousel Image"
              />
            </li>
            <li
              key={7}
              className={styles.swiperSlide}
            >
              <Image
                image={{
                  desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/ISxTM_SandDune_gallery_1_850x.jpg?v=1622234925",
                  mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/ISxTM_SandDune_gallery_1_850x.jpg?v=1622234925",
                }}
                altText="Irina Shayk Carousel Image"
              />
            </li>
            <li
              key={8}
              className={styles.swiperSlide}
            >
              <Image
                image={{
                  desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/ISxTM_SandDune_gallery_2_850x.jpg?v=1622234926",
                  mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/ISxTM_SandDune_gallery_2_850x.jpg?v=1622234926",
                }}
                altText="Irina Shayk Carousel Image"
              />
            </li>
            <li
              key={9}
              className={styles.swiperSlide}
            >
              <Image
                image={{
                  desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/ISxTM_SandDune_gallery_3_850x.jpg?v=1622234925",
                  mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/ISxTM_SandDune_gallery_3_850x.jpg?v=1622234925",
                }}
                altText="Irina Shayk Carousel Image"
              />
            </li>
            <li
              key={10}
              className={styles.swiperSlide}
            >
              <Image
                image={{
                  desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/ISxTM_SandDune_gallery_4_850x.jpg?v=1622234925",
                  mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/ISxTM_SandDune_gallery_4_850x.jpg?v=1622234925",
                }}
                altText="Irina Shayk Carousel Image"
              />
            </li>
            <li
              key={11}
              className={styles.swiperSlide}
            >
              <Image
                image={{
                  desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/ISxTM_SandDune_gallery_5_850x.jpg?v=1622234925",
                  mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/ISxTM_SandDune_gallery_5_850x.jpg?v=1622234925",
                }}
                altText="Irina Shayk Carousel Image"
              />
            </li>
          </Swiper>
          <button
            className={`${styles.swiperButtonPrev} swiper-navigation-prev`}
            onClick={() => swiper2.slidePrev()}
            aria-label="Previous"
          />
          <button
            className={`${styles.swiperButtonNext} swiper-navigation-next`}
            onClick={() => swiper2.slideNext()}
            aria-label="Next"
          />
        </div>
        <p className={styles.text}>
          Tamara’s life work is encouraging women to stand in their power—and few do it quite like Irina. Born from months of sketching, material selection, fittings, and mom-to-mom bonding (all over Zoom), this is a capsule of shoes to wear while just being... you. (So always.)
        </p>
      </section>

      {/* SS Products 1x1x1 */}
      {/*<ul className={styles.module3}>*/}
      {/*  <ProductTile*/}
      {/*    index="0"*/}
      {/*    style={nodes[1]}*/}
      {/*    customStyles={styles}*/}
      {/*    origin={`irinashayk-ss21`}*/}
      {/*    collection="Irina Shayk SS21"*/}
      {/*  />*/}
      {/*  <ProductTile*/}
      {/*    index="1"*/}
      {/*    style={nodes[2]}*/}
      {/*    customStyles={styles}*/}
      {/*    origin={`irinashayk-ss21`}*/}
      {/*    collection="Irina Shayk SS21"*/}
      {/*  />*/}
      {/*  <ProductTile*/}
      {/*    index="2"*/}
      {/*    style={nodes[0]}*/}
      {/*    customStyles={styles}*/}
      {/*    origin={`irinashayk-ss21`}*/}
      {/*    collection="Irina Shayk SS21"*/}
      {/*  />*/}
      {/*</ul>*/}

      <section className={styles.moduleVideo3} id="style-video">
        <Heading
          tag="h1"
          level="1"
          className={styles.heading}
        >
          How to Style It
        </Heading>
        <div className={styles.video1x1x1}>
          <div className={styles.videoContainer}>
            {typeof window !== "undefined" && window.outerWidth < 768 &&
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <video playsInline poster="https://cdn.shopify.com/s/files/1/1103/4464/files/still_mobile2_850x.jpg?v=1623109262"  className={styles.video3} ref={videoEl3} data-playing="false">
              <source
                src="https://cdn.shopify.com/s/files/1/1103/4464/files/ISxTM_SandDune_styled_mobile_30_v2.mp4?v=1623109261"
                type="video/mp4" />
            </video>
            }
            {typeof window !== "undefined" && window.outerWidth >= 768 &&
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <video playsInline poster="https://cdn.shopify.com/s/files/1/1103/4464/files/ISxTM_SandDune_styled_3x4_STILL_850x.jpg?v=1623348079" className={styles.video3} ref={videoEl3} data-playing="false">
              <source
                src="https://cdn.shopify.com/s/files/1/1103/4464/files/ISxTM_SandDune_styled_3x4.mp4?v=1623348079"
                type="video/mp4" />
            </video>
            }
            <button className={styles.playBtn} aria-label="Play" />
            <p className={styles.text}>Hit Play for Irina's Sand Dune styling tips.</p>
          </div>
          <div className={styles.videoContainer}>
            {typeof window !== "undefined" && window.outerWidth < 768 &&
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <video playsInline poster="https://cdn.shopify.com/s/files/1/1103/4464/files/ISxTM_SunValley_styled_MOBILE_STILL_850x.jpg?v=1623348019"  className={styles.video3} ref={videoEl1} data-playing="false">
              <source
                src="https://cdn.shopify.com/s/files/1/1103/4464/files/ISxTM_SunValley_styled_MOBILE.mp4?v=1623348019"
                type="video/mp4" />
            </video>
            }
            {typeof window !== "undefined" && window.outerWidth >= 768 &&
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <video playsInline poster="https://cdn.shopify.com/s/files/1/1103/4464/files/ISxTM_SunValley_styled_3x4_STILL_850x.jpg?v=1623348079" className={styles.video3} ref={videoEl1} data-playing="false">
              <source
                src="https://cdn.shopify.com/s/files/1/1103/4464/files/ISxTM_SunValley_styled_3x4.mp4?v=1623348079"
                type="video/mp4" />
            </video>
            }
            <button className={styles.playBtn} aria-label="Play" />
            <p className={styles.text}>Irina’s styling tips part 2, featuring Sun Valley.</p>
          </div>
          <div className={styles.videoContainer}>
            {typeof window !== "undefined" && window.outerWidth < 768 &&
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <video playsInline poster="https://cdn.shopify.com/s/files/1/1103/4464/files/escape_HowToStyle_mobile_STILL_850x.jpg?v=1624470826"  className={styles.video3} ref={videoEl5} data-playing="false">
              <source
                src="https://cdn.shopify.com/s/files/1/1103/4464/files/escape_HowToStyle_mobile.mp4?v=1624470826"
                type="video/mp4" />
            </video>
            }
            {typeof window !== "undefined" && window.outerWidth >= 768 &&
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <video playsInline poster="https://cdn.shopify.com/s/files/1/1103/4464/files/escape_HowToStyle_desktop_STILL_850x.jpg?v=1624470783" className={styles.video3} ref={videoEl5} data-playing="false">
              <source
                src="https://cdn.shopify.com/s/files/1/1103/4464/files/escape_HowToStyle_desktop.mp4?v=1624470784"
                type="video/mp4" />
            </video>
            }
            <button className={styles.playBtn} aria-label="Play" />
            <p className={styles.text}>Watch Irina show you how to style Escape.</p>
          </div>
        </div>
      </section>

      <section className={styles.moduleVideo2} id="relationship-video">
        <Heading
          tag="h1"
          level="1"
          className={styles.heading}
        >
          Irina's Relationship Advice
        </Heading>
        <div className={styles.videoContainer}>
          {typeof window !== "undefined" && window.outerWidth < 768 &&
          // eslint-disable-next-line jsx-a11y/media-has-caption
          <video playsInline poster="https://cdn.shopify.com/s/files/1/1103/4464/files/Irina-video-poster-image_850x.jpg?v=1622562440"  className={styles.video} ref={videoEl2} data-playing="false">
            <source
              src="https://cdn.shopify.com/s/files/1/1103/4464/files/ISxTM_Relationship_footer_850x850_FULL.mp4?v=1622563600"
              type="video/mp4" />
          </video>
          }
          {typeof window !== "undefined" && window.outerWidth >= 768 &&
          // eslint-disable-next-line jsx-a11y/media-has-caption
          <video playsInline className={styles.video2} ref={videoEl2} data-playing="false">
            <source
              src="https://cdn.shopify.com/s/files/1/1103/4464/files/ISxTM_Relationship_footer_2000x1000__FULL_1.mp4?v=1622563758"
              type="video/mp4" />
          </video>
          }
          <button className={styles.playBtn} aria-label="Play" />
        </div>
      </section>

      <section className={styles.module1}>
        <p className={styles.text}>
          Don’t miss more coming with Irina, enter your email here to be notified.
        </p>
        <div className={styles.formContainer}>
          <EmailCapture
            submitText="Sign Up"
            confirmationHeading={{heading: "Thank You"}}
            confirmationText=""
            listId="UUpRVJ"
            source="irina"
            formName="Default"
            offerDiscountCode={false}
            welcomeSeries={true}
            location={location}
          />
        </div>
      </section>
    </>
  )
}

export default IrinashaykSS21

// export const query = graphql`
//     query IrinashaykSS21Query {
//         allProductStyle(filter: {handle: {in: [
// #            "sun-valley-vitello-sandals",
// #            "sand-dune-vitello-sandals",
// #            "escape-nappa-boots",
// #            "sweet-revenge-stretch-nappa-boot-s",
// #            "iri-nappa-boots",
// #            "spike-suede-boots",
//         ]}}) {
//             nodes {
//                 ...PLPStyleFragment
//                 handle
//             }
//         }
//     }
// `
